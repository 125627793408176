import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BackTop } from "antd";
import { Home, NoPage } from "./Pages";
import { Navbar, Footer, Preloader } from "./Components";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000); // 2 seconds delay
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="bg-gradient-to-b from-violet-900 to-slate-950">
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/*" element={<NoPage />} />
            </Routes>
            <Footer />
          </BrowserRouter>
          <BackTop />
        </div>
      )}
    </>
  );
};

export default App;