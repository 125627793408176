import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { herobg, banner, x, discord, dextools, dexscreener, birdeye, solscan, coingecko, cmc } from "../Assets";
import { SocialIcons } from "../Components";

const Home = () => {
  // Define the icon data with disabled state
  const socialIcons = [
    {
      href: "https://x.com/thedogsolana",
      src: x,
      alt: "Twitter",
      disabled: false, // Active button
    },
    {
      href: "https://discord.gg/VEQZp6WHgR",
      src: discord,
      alt: "Discord",
      disabled: false, // Active button
    },
    {
      href: "https://dextools.io/app/en/ethereum/pair-explorer/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: dextools,
      alt: "Dextools",
      disabled: true, // Disabled button
    },
    {
      href: "https://www.dexscreener.com/ethereum/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: dexscreener,
      alt: "Dexscreener",
      disabled: true, // Disabled button
    },
    {
      href: "https://www.dexscreener.com/ethereum/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: birdeye,
      alt: "Birdeye",
      disabled: true, // Disabled button
    },
    {
      href: "https://www.dexscreener.com/ethereum/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: solscan,
      alt: "Solscan",
      disabled: true, // Disabled button
    },
    {
      href: "https://www.dexscreener.com/ethereum/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: coingecko,
      alt: "Coingecko",
      disabled: true, // Disabled button
    },
    {
      href: "https://www.dexscreener.com/ethereum/0x0de4a8cf1a0531f8f93b4f5664d74696bc57d3b5",
      src: cmc,
      alt: "CMC",
      disabled: true, // Disabled button
    },
  ];

  return (
    <div>
      {/* Landing */}
      <div
        className="relative bg-cover bg-center"
        style={{
          backgroundImage: `url(${herobg})`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

        {/* Section */}
        <section className="relative min-h-screen flex items-center justify-center">
          <div className="text-center px-4 xl:px-24">
            <h1 className="text-white font-bold text-3xl xl:text-8xl font-zcool animate-fadeInUp">
              Leading the charge to SOLANADAWG the next billion normies.
            </h1>

            <p className="text-white max-w-xl text-2xl md:text-3xl leading-relaxed mx-auto font-jost mt-4">
              Wooooof! 🐶🐶🐶
            </p>

            {/* Social Media Icons */}
            <SocialIcons icons={socialIcons} />
          </div>
        </section>
      </div>

      {/* About Section */}
      <div className="py-16 px-4 xl:px-24">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-white mb-8 font-zcool">
            About THEDOG
          </h2>
          <img
            src={banner}
            alt="About THEDOG"
            className="w-full max-w-2xl mx-auto rounded-lg shadow-lg mb-8"
          />
          <p className="text-lg text-white leading-relaxed font-jost">
            In a world where each blockchain has its own unique paw print,
            Solana stands out with its vibrant and one-of-a-kind Solana-colored
            fur. However, many individuals, known as Normies, are still unaware
            of this fascinating realm.
          </p>
          <p className="text-lg text-white leading-relaxed font-jost mt-4">
            $THEDOG is on a mission to bring the wonders of Solana to everyone,
            particularly those new to the crypto space. With its joyful bark and
            steadfast presence, $THEDOG is here to lead the Normies into the
            exciting world of Solana and all it has to offer.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;