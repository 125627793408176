import React from 'react';

const SocialIcons = ({ icons }) => {
  return (
    <div className="m-4">
      {/* Flex container with wrap to make the icons responsive */}
      <ul className="flex flex-wrap justify-center items-center space-x-6">
        {icons.map((icon, index) => (
          <li
            key={index}
            className={`w-10 h-10 rounded-full flex items-center justify-center border border-white shadow-md mb-4 ${
              icon.disabled
                ? 'bg-gray-500 cursor-not-allowed'
                : 'bg-gradient-to-b from-purple-900 to-purple-500'
            }`}
          >
            {/* If disabled, prevent clicks */}
            <a
              href={icon.disabled ? '#' : icon.href}
              className={icon.disabled ? 'cursor-not-allowed' : ''}
            >
              <img
                src={icon.src}
                width={30}
                height={30}
                alt={icon.alt}
                className="rounded-full"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialIcons;