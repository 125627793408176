import React from "react";
import {  preloader } from "../Assets";

const Preloader = () => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70"
      style={{
        backgroundImage: `url(${preloader})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-center">
        <h1 className="text-white font-bold text-5xl font-zcool mb-6">Woof! Woof! </h1>
        <div className="flex justify-center items-center space-x-2">
          <div className="w-4 h-4 bg-white rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-white rounded-full animate-bounce delay-150"></div>
          <div className="w-4 h-4 bg-white rounded-full animate-bounce delay-300"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;