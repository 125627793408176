import React from "react";
import { discord, x } from "../Assets";
import SocialIcons from "./SocialIcons";  // Import the SocialIcons component

const current = new Date();
const date = current.getFullYear();

const Footer = () => {
  // Define the icon data with disabled state
  const socialIcons = [
    {
      href: "https://x.com/thedogsolana",
      src: x,
      alt: "Twitter",
      disabled: false, // Active button
    },
    {
      href: "https://discord.gg/VEQZp6WHgR",
      src: discord,
      alt: "Discord",
      disabled: false, // Active button
    },
  ];

  return (
    <div>
      <footer className="bottom-0 left-0 w-full text-slate-50 bg-opacity-0 px-4 py-5 mx-auto md:px-8 z-50 text-sm" style={{ pointerEvents: "none" }}>
        <div className="sm:mx-auto sm:text-center"></div>
        <div className="mt-8 items-center justify-between sm:flex flex flex-col pointer-events-auto">
          {/* Social Media Icons */}
          <div className="m-4">
            <SocialIcons icons={socialIcons} />
          </div>

          {/* Footer Text */}
          <div className="mt-4 sm:mt-0 text-center text-white bg-opacity-75 bg-black py-2 px-4 rounded-lg">
            &copy; {date} TheDog All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;